let env = {
    mintContract: "0xA4A42d4e10D106552e0175e804cA63ED1Db625B5",
    
    openSeaCollection: "https://opensea.io/collection/sparesouls",
    raribleCollection: "https://rarible.com/sparesouls/",
    looksRareCollection: "",
    twitter_url: "https://twitter.com/sparesoulslabs",
    email: "emailto:sparesouls.labs@gmail.com",
    story: false,
    
    // networkRequired: "mainnet",
    networkRequired: "goerli",


    networks: {
        mainnet: {
            chainId: `0x${Number(1).toString(16)}`,
            chainName:"Ethereum Mainnet",
            nativeCurrency:{
                name:"Ether",
                symbol:"ETH",
                decimals:18 
            },
            rpcUrls:[
                "https://main-light.eth.linkpool.io",
                "https://main-rpc.linkpool.io",
                "https://eth-rpc.gateway.pokt.network",
                "https://cloudflare-eth.com",
                "https://rpc.flashbots.net",
            ],
            etherscanTX: 'https://etherscan.io/tx/',
            etherscanAddress: 'https://etherscan.io/address/',
            blockExplorerUrls:[
                "https://etherscan.io"
            ]},
        goerli: {
          chainId: `0x${Number(5).toString(16)}`,
          chainName:"Goerli ETH Test Network",
            nativeCurrency:{
                name:"Goerli",
                symbol:"GRLI",
                decimals:18 
            },
            rpcUrls:[
                "https://goerli.infura.io/v3/{API_KEY}",
            ],
            etherscanTX: 'https://goerli.etherscan.io/tx/',
            etherscanAddress: 'https://goerli.etherscan.io/address/',
            blockExplorerUrls:[
                "https://goerli.etherscan.io"
            ]},
        polygon: {
          chainId: `0x${Number(137).toString(16)}`,
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
          },
          rpcUrls: ["https://polygon-rpc.com/"],
          blockExplorerUrls: ["https://polygonscan.com/"]
        },
        bsc: {
          chainId: `0x${Number(56).toString(16)}`,
          chainName: "Binance Smart Chain Mainnet",
          nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BNB",
            decimals: 18
          },
          rpcUrls: [
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed2.binance.org",
            "https://bsc-dataseed3.binance.org",
            "https://bsc-dataseed4.binance.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "wss://bsc-ws-node.nariox.org"
          ],
          blockExplorerUrls: ["https://bscscan.com"]
        }
      }
}

export default env;
