import React from 'react';
import { Button } from "@chakra-ui/react"
// import { LockIcon } from '@chakra-ui/icons'


const MetamaskShowETH = ({ dataEth, setDataEth }) => {

    const logout = () => {
        setDataEth();
    }

    return (
        <div style={{ display: 'inline', verticalAlign: 'middle', zIndex: '100', minWidth: '130px'}}>

            <Button
                backgroundColor="#121314"
                color="#FAFAFA"
                borderRadius="0px"
                cursor="pointer"
                fontFamily="inherit"
                fontSize="24"
                padding="5px"
                paddingLeft="65px"
                paddingRight="65px"
                marginTop="0px"
                zIndex="100"
                onClick={logout}
            >{dataEth?.accountEth?.account.substring(0,6)}...
                    {/* {dataEth?.accountEth?.account.substring(dataEth?.accountEth?.account.length-4)}
                        <IconButton
                        colorScheme='gray'
                        aria-label='Logout'
                        size='sm'
                        isRound='true'
                        icon={<LockIcon />}
                        onClick={logout}
                    /> */}
            </Button>

        </div>
    )
}

export default MetamaskShowETH;