import { Flex } from "@chakra-ui/react"
const soulsArray=["spareSouls-1.png", "spareSouls-2.png", "spareSouls-3.png", "spareSouls-4.png"]                        

const ProjectDescription=({dataEth, setDataEth}) => {

    const spare=soulsArray[Math.floor(Math.random() * soulsArray.length)];

    return(
        <>
            <Flex justify="center" align="center" height="100%" paddingBottom="0px" paddingTop="0px">
                {/* <Image className="descTitle" src={banner} /> */}
            </Flex>
            <Flex justify="center" align="center" height="100%" paddingBottom="30px" marginTop="-2px">
                <img alt="SpareSouls" className="descImage" src={require("../assets/"+spare)}/>
            </Flex>
        </>
    )
}

export default ProjectDescription;