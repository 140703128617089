import { useState, useRef } from 'react';

import './App.css';

// import Toast from './toast/toast';
import NavBar from './navbar/NavBar';
import ProjectDescription from './ProjectDescription/ProjectDescription';
import ProjectMintSuccess from './ProjectDescription/ProjectMintSuccess';
import ProjectStatus from './ProjectDescription/ProjectStatus';

import PageFooter from './pageSections/pageFooter';

import { useToastHook } from './toast/toast';

function App() {
  const [dataEth, setDataEth]=useState([
    { isConnected: false,
      networkRequired: null,
      accountEth: {},
      contractInfo: {} } ]);

  const [message, setMessage]=useToastHook();
  const [dataNav, setDataNav]=useState("about");


  const aboutRef=useRef(null);

  return (
    
      <div className="App">
          <div className="container">
            <div className="backImage" />
            
            <div className="containerInside">
                <NavBar dataEth={dataEth} setDataEth={setDataEth} 
                        message={message} setMessage={setMessage}
                        dataNav={dataNav} setDataNav={setDataNav}
                />

                <ProjectMintSuccess dataEth={dataEth} setDataEth={setDataEth} />
                
                <div className="above">
                    <ProjectDescription dataEth={dataEth} setDataEth={setDataEth} />
                    <ProjectStatus dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />
                </div>
                
                <PageFooter />

            </div>
          </div>

      </div>
      
  );


}



export default App;
