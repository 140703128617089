import { useState } from "react";
import { ethers  } from "ethers"; //BigNumber
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"
// import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'

import Contract from '../Contract.json'
import env from '../env';

import updateContractInfo from '../Metamask/updateContractInfo';

import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

const ProjectMint=({ dataEth, setDataEth, message, setMessage }) => {
    const [ mintAmount, setMintAmount ]=useState(1);
    const [ mintCost, setMintCost ]=useState();

    const onMintedSuccess = (_mintedSuccess, freeMint) => {
        
        let mensaje={
            title: (freeMint? "Free " : "") + "Mint Succesful",
            description: "Please allow a few minutes to see your new NFTs in your collection",
            status: "success",
        }
        if (!_mintedSuccess) {
            mensaje={
                title: "Error " + (freeMint? "Free " : "") +  + "Minting",
                description: "Please check your wallet transaction on etherscan",
                status: "error",
            }
        }
        setMessage(mensaje)
    }

    async function handleMintClick() {
        handleMint(false);
    }

    async function getCost(_count) {
        const mintPrice = dataEth.contractInfo.mintPrice;
        const discountPrice = dataEth.contractInfo.preSalePrice;

        if (dataEth.contractInfo.preSaleDiscount) {
            console.log("P1");
            return mintPrice*_count;
        } else if (dataEth.contractInfo.freeMint) {
            console.log("P2");
            return mintPrice*(_count - 1);
        } else if (dataEth.contractInfo.discountMint) {
            console.log("P3");
            return discountPrice + mintPrice*(_count - 1);
        } 
        console.log("P4");
        return mintPrice*_count;
    }

    async function handleMint(freeMint) {
        if (!dataEth?.accountEth) return;
        if (dataEth.contractInfo.mintState===0) return;
        console.log(dataEth.contractInfo.mintState, dataEth.contractInfo.merkleProof)
        if (dataEth.contractInfo.mintState===1 && !dataEth.contractInfo.merkleProof) {
                setMessage({title: "Pre-Minting not allowed", description: "Wallet is not in FreeMint List / Allow List" , status: "error"})
                return
        }
        if (!dataEth.contractInfo.enabledMint) {
            setMessage({title: "Minting not enabled", description: "Please wait to mint your spare soul" , status: "error"})
            return;
        }

        try {
            
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                env.mintContract,
                Contract.abi,
                signer
            );

            let _mintCost=await getCost(mintAmount);
            let response;
        
            if (dataEth.contractInfo.mintState===1) {
                response = await contract.preSaleMint(
                                    parseInt(mintAmount), 
                                    dataEth.contractInfo.merkleProof,
                                    {
                                        value: ethers.utils.parseEther((_mintCost).toString()),
                                    });
            } else {
                response = await contract.mint(
                                    parseInt(mintAmount), 
                                    dataEth.contractInfo.merkleProof,
                                    {
                                        value: ethers.utils.parseEther((_mintCost).toString()),
                                    });
            }
        
            const txHash=response.hash;
            const data = {
                    txID: txHash, waitingForTx: true, mintedSuccess: false,
            };

            setDataEth((prevState) => ({
                ...prevState,
                tx: data
            }));
            
            const txReceipt=await response.wait();
            const mintedSuccess=txReceipt && txReceipt.blockNumber && txReceipt.blockNumber!==undefined;
        
            const data2 = { 
                txID: txHash, 
                txReceipt: txReceipt, 
                waitingForTx: !mintedSuccess, 
                mintedSuccess: mintedSuccess };
            
            const contractInfo=await updateContractInfo(contract, dataEth.accountEth.account);
            setDataEth((prevState) => ({
                ...prevState,
                tx: data2,
                contractInfo: contractInfo
            }));
            
            onMintedSuccess(mintedSuccess, freeMint);
        
        } catch (error) {
            // @ts-ignore
            
            console.error(error);
            let msgDecrypted=getParsedEthersError(error);
            if (msgDecrypted.errorCode==="REJECTED_TRANSACTION") {
                setMessage({title: "Error", description: "User rejected transaction", status: "error"})
            } else {
                console.log(msgDecrypted);
                setMessage({title: "Error", description: (msgDecrypted.context), status: "error"})
            }

            return;
        }

    } 

    const handleDecrement = async () => {
        let XA=mintAmount;
        if (mintAmount<=1) {
            setMintCost(await getCost(1));
            return;
        }
        setMintAmount(mintAmount-1);
        setMintCost(await getCost(XA-1));
    }

    const handleIncrement = async () => {
        let XA=mintAmount;
        if (mintAmount>=dataEth.contractInfo.maxMintsState) {
            setMintCost(await getCost(XA));
            return;
        }
        setMintAmount(mintAmount+1);
        setMintCost(await getCost(XA+1));
    }

    const availableMint = () => {
        return (
            <>
                <Flex align="center" justify="center">
                    <Input
                        readOnly
                        fontFamily="inherit"
                        width="150px"
                        height="40px"
                        textAlign="center"
                        paddingLeft="0px"
                        marginTop="10px"
                        color="black"
                        backgroundColor="transparent"
                        borderColor="transparent"
                        value={mintAmount.toString()+" "+dataEth.contractInfo.tokenSymbol} />
                </Flex>
                <Flex align="center" justify="center">
                    <Box paddingRight="15" color="black.800" onClick={(value)=>handleDecrement()} >
                        <Button
                            backgroundColor='black'
                            color='#fafafa'
                            aria-label={'Minus '+dataEth.contractInfo.tokenSymbol}
                        >-</Button>
                    </Box>
                    <Button
                        backgroundColor="black"
                        borderRadius="0px"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        disabled={!dataEth.contractInfo.enabledMint}
                        onClick={handleMintClick}
                    >
                        MINT
                    </Button>
                    <Box align="center" paddingLeft="15" color="black" onClick={(value)=>handleIncrement()} >
                        <Button
                            backgroundColor='black'
                            color='#fafafa'
                            padding="15px"
                            aria-label={'Add '+dataEth.contractInfo.tokenSymbol}
                        >+</Button>
                    </Box>
                </Flex>

                
                <Text marginTop="10px" fontSize="12px" textShadow="0 2px #DADADE">{mintCost?.toFixed(3)} Ξ</Text>
                <Text fontSize="12px" textShadow="0 2px #DADADE">{dataEth?.contractInfo?.totalSupply ? dataEth.contractInfo.totalSupply + " / " + dataEth.contractInfo.MAX_SUPPLY : ""}</Text>


        </>
        );
    }

    return(
        <>
            {availableMint()}
        </>
    )

}

export default ProjectMint;