import { ethers } from "ethers";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const walletData=require('../walletData.json');


function buf2Hex(x) { 
    return '0x'+x.toString('hex').toUpperCase(); }

async function getMerkleProof(account) {
    let leaf=keccak256(account).toString('hex');
    let _merkleProof=walletData[buf2Hex(leaf)];
    return _merkleProof;
}

const getLists = async(account, _merkleProof) => {
    let _freeMintMerkleRoot=walletData['rootHash_FreeMint'];
    let _preSaleMerkleRoot=walletData['rootHash_Discounted'];

    let MerkleProof=new MerkleTree([], keccak256, { sortPairs: true});
    let _leaf=keccak256(account).toString('hex');

    let freeMint=await MerkleProof.verify(_merkleProof, _leaf, _freeMintMerkleRoot);
    let discountMint=await MerkleProof.verify(_merkleProof, _leaf, _preSaleMerkleRoot);

    return [freeMint, discountMint];
}


const updateContractInfo = async (contract, walletAddress) => {
    let discountPrice=0,mintPrice=0,totalSupply=0,
        MAX_SUPPLY=0,MAX_PER_MINT=0,MAX_PRESALE_MINTS=0,
        mintState=0,maxMintsState=0,tokenSymbol='',
        preSaleDiscount=0,preSaleMintsUsed=0,
        loadedContract=false,
        _freeMint=false, _discountMint=false,
        _merkleProof=[],
        _enabledMint = false;

    try {
        mintState = await contract.saleState();

        let response = await contract.price();
        if (response)  mintPrice=ethers.utils.formatEther(response);

        response = await contract.preSalePrice();
        if (response) discountPrice=ethers.utils.formatEther(response);
        
        MAX_SUPPLY = ethers.utils.formatUnits(await contract.MAX_SUPPLY(), 0);
        MAX_PER_MINT = parseInt(ethers.utils.formatUnits(await contract.MAX_PER_MINT(), 0));
        MAX_PRESALE_MINTS = parseInt(ethers.utils.formatUnits(await contract.MAX_PRESALE_MINTS(), 0));
        totalSupply = ethers.utils.formatUnits(await contract.totalSupply(), 0);

        preSaleMintsUsed=parseInt(ethers.utils.formatUnits(await contract.preSaleMintsUsed(walletAddress),0));
        preSaleDiscount=await contract.preSaleDiscount(walletAddress);

        maxMintsState=mintState===1? MAX_PRESALE_MINTS-preSaleMintsUsed : MAX_PER_MINT;
        tokenSymbol=await contract.symbol();


        // User Free Mint / Discount Mint  
        _merkleProof = await getMerkleProof(walletAddress);
        [ _freeMint, _discountMint ] = await getLists(walletAddress, _merkleProof);

        // Enabled Mint (Free Mint / Final Mint)
        if (mintState===1) {
            if (!(_freeMint || _discountMint)) maxMintsState = 0;
            _enabledMint = maxMintsState>0;                
        } else if (mintState===2) {
            _enabledMint = totalSupply<=MAX_SUPPLY;
        }

        loadedContract = true;

    } catch (error) {
        console.error(error)
        let msgDecrypted=getParsedEthersError(error);
        const _errMsg = {title: "Connection Error", description: msgDecrypted.context, status: "error"};
        console.error(_errMsg)
    }

    return { 
            discountPrice: discountPrice, mintPrice: mintPrice, 
            totalSupply: totalSupply,
            
            MAX_SUPPLY: MAX_SUPPLY, MAX_PER_MINT: MAX_PER_MINT, MAX_PRESALE_MINTS: MAX_PRESALE_MINTS,
            maxMintsState: maxMintsState,

            enabledMint: _enabledMint,
            freeMint: _freeMint, discountMint:_discountMint, merkleProof: _merkleProof,
            
            preSaleDiscount: preSaleDiscount, preSaleMintsUsed: preSaleMintsUsed,
            tokenSymbol: tokenSymbol, mintState: mintState, loadedContract: loadedContract };
}


export default updateContractInfo;
