import React from "react";
import { Flex } from "@chakra-ui/react"
import Metamask from '../Metamask/Metamask';

// import twitter from "../assets/twitter.png"
// import email from "../assets/email.png"
// import opensea from "../assets/opensea.png"
// import etherscan from "../assets/etherscan.png"

// import env from '../env';

const NavBar = ({ props,
    dataEth, setDataEth, 
    message, setMessage,
    dataNav, setDataNav,
    
    }) => {

    // const scrollToSection = (nombre, elementRef) => {
    //     setDataNav(nombre);
    //     if (!elementRef) return;
    //     if (!elementRef.current) return;
    //     window.scrollTo({
    //       top: elementRef.current.offsetTop,
    //       behavior: "smooth",
    //     });
    //   };

    return(
        <>
        <Flex backgroundColor="#FAFEFA" justify="None" margin="0px" padding="0px" align="right" zIndex="10000" >
            <Flex 
                color="#FAFAFA"
                justify="None"
                align="right"
                width="85%" 
                padding="10px"
                marginLeft="50px"
            >
            </Flex>
            <Flex 
                justify="space-around"
                align="right"
                width="15%" 
                padding="10px"
                marginRight="50px"
            >
                <Metamask dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />
            </Flex>
        </Flex>

        </>



                
            

    )
}

export default NavBar;
